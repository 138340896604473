import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced:true,
    state(){
        return {
            navMenu : true,
            footerMenu : false
        }
    },
    mutations:mutations,
    actions:actions,
    getters:getters
};
