<template>
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
        <div :class="navMenuStatus?'':'hideNavMenu' ">
          <nav-header></nav-header>
        </div>
        <router-view />
        <div :class="footerMenuStatus?'':'hideFooterMenu' ">
          <nav-footer></nav-footer>
        </div>



      <!-- Advance Search Start -->
      <div class="w3-ch-sideBar w3-bar-block w3-card-2 w3-animate-right"  style="display:none;right:0;" id="rightMenu">
          <div class="search-sidebar_header">
              <h4 class="ssh_heading">Search Filter</h4>
              <a href="javascript:void(0);" class="clear_all">Clear All</a><a href="#search_open" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed _filter-ico"><i class="fa fa-sliders"></i></a>
              <button onclick="closeRightMenu()" class="w3-bar-item w3-button w3-large"><i class="ti-close"></i></button>
          </div>
          <div class="rightMenu-scroll">
              <div class="right-ch-sideBar" id="side-scroll">

                  <div class="search-inner p-0">

                      <div class="filter-search-box pb-0">
                          <div class="form-group">
                              <input type="text" class="form-control" placeholder="Search by keywords...">
                          </div>
                          <div class="form-group">
                              <input type="text" class="form-control" placeholder="Location, Zip..">
                          </div>
                      </div>

                      <div class="filter_wraps">

                          <!-- Job categories Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#categories" data-toggle="collapse" aria-expanded="true" role="button">Job Categories</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse show" id="categories" data-parent="#categories">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="a1" class="checkbox-custom" name="ADA" type="checkbox" checked="">
                                                          <label for="a1" class="checkbox-custom-label">IT Computers (62)</label>
                                                          <ul class="no-ul-list filter-list">
                                                              <li>
                                                                  <input id="aa1" class="checkbox-custom" name="ADA" type="checkbox">
                                                                  <label for="aa1" class="checkbox-custom-label">Web Design (31)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="aa2" class="checkbox-custom" name="Parking" type="checkbox">
                                                                  <label for="aa2" class="checkbox-custom-label">Web development (20)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="aa3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                                  <label for="aa3" class="checkbox-custom-label">SEO Services (43)</label>
                                                              </li>
                                                          </ul>
                                                      </li>
                                                      <li>
                                                          <input id="a2" class="checkbox-custom" name="Parking" type="checkbox">
                                                          <label for="a2" class="checkbox-custom-label">Financial Service (16)</label>
                                                      </li>
                                                      <li>
                                                          <input id="a3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="a3" class="checkbox-custom-label">Art, Design, Media (22)</label>
                                                      </li>
                                                      <li>
                                                          <input id="a4" class="checkbox-custom" name="Mother" type="checkbox">
                                                          <label for="a4" class="checkbox-custom-label">Coach & Education (21)</label>
                                                      </li>
                                                      <li>
                                                          <input id="a5" class="checkbox-custom" name="Outdoor" type="checkbox">
                                                          <label for="a5" class="checkbox-custom-label">Apps Developements (17)</label>
                                                          <ul class="no-ul-list filter-list">
                                                              <li>
                                                                  <input id="ab1" class="checkbox-custom" name="ADA" type="checkbox">
                                                                  <label for="ab1" class="checkbox-custom-label">IOS Development (12)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="ab2" class="checkbox-custom" name="Parking" type="checkbox">
                                                                  <label for="ab2" class="checkbox-custom-label">Android Development (04)</label>
                                                              </li>
                                                          </ul>
                                                      </li>
                                                      <li>
                                                          <input id="a6" class="checkbox-custom" name="Pet" type="checkbox">
                                                          <label for="a6" class="checkbox-custom-label">Writing & Translation (04)</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Job Locations Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#locations" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed">Job Locations</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse" id="locations" data-parent="#locations">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="b1" class="checkbox-custom" name="ADA" type="checkbox" checked="">
                                                          <label for="b1" class="checkbox-custom-label">Australia (21)</label>
                                                      </li>
                                                      <li>
                                                          <input id="b2" class="checkbox-custom" name="Parking" type="checkbox">
                                                          <label for="b2" class="checkbox-custom-label">New Zeland (12)</label>
                                                      </li>
                                                      <li>
                                                          <input id="b3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="b3" class="checkbox-custom-label">United Kingdom (21)</label>
                                                          <ul class="no-ul-list filter-list">
                                                              <li>
                                                                  <input id="ac1" class="checkbox-custom" name="ADA" type="checkbox">
                                                                  <label for="ac1" class="checkbox-custom-label">London (06)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="ac2" class="checkbox-custom" name="Parking" type="checkbox">
                                                                  <label for="ac2" class="checkbox-custom-label">Manchester (07)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="ac3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                                  <label for="ac3" class="checkbox-custom-label">Birmingham (08)</label>
                                                              </li>
                                                          </ul>
                                                      </li>
                                                      <li>
                                                          <input id="b4" class="checkbox-custom" name="Mother" type="checkbox">
                                                          <label for="b4" class="checkbox-custom-label">United State (04)</label>
                                                          <ul class="no-ul-list filter-list">
                                                              <li>
                                                                  <input id="ad1" class="checkbox-custom" name="ADA" type="checkbox">
                                                                  <label for="ad1" class="checkbox-custom-label">New York (32)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="ad2" class="checkbox-custom" name="Parking" type="checkbox">
                                                                  <label for="ad2" class="checkbox-custom-label">Washington (42)</label>
                                                              </li>
                                                              <li>
                                                                  <input id="ad3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                                  <label for="ad3" class="checkbox-custom-label">Los Angeles (22)</label>
                                                              </li>
                                                          </ul>
                                                      </li>
                                                      <li>
                                                          <input id="b5" class="checkbox-custom" name="Outdoor" type="checkbox">
                                                          <label for="b5" class="checkbox-custom-label">India (15)</label>
                                                      </li>
                                                      <li>
                                                          <input id="b6" class="checkbox-custom" name="Pet" type="checkbox">
                                                          <label for="b6" class="checkbox-custom-label">Singapore (09)</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Job Skills Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#skills" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed">Skills</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse" id="skills" data-parent="#skills">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="c1" class="checkbox-custom" name="ADA" type="checkbox" checked="">
                                                          <label for="c1" class="checkbox-custom-label">Administrative (15)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c2" class="checkbox-custom" name="Parking" type="checkbox">
                                                          <label for="c2" class="checkbox-custom-label">iPhone & Android (33)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="c3" class="checkbox-custom-label">Java & AJAX (32)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c4" class="checkbox-custom" name="Mother" type="checkbox">
                                                          <label for="c4" class="checkbox-custom-label">Account Manager (21)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c5" class="checkbox-custom" name="Outdoor" type="checkbox">
                                                          <label for="c5" class="checkbox-custom-label">WordPress (32)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c6" class="checkbox-custom" name="Pet" type="checkbox">
                                                          <label for="c6" class="checkbox-custom-label">Magento (42)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c7" class="checkbox-custom" name="Beauty" type="checkbox">
                                                          <label for="c7" class="checkbox-custom-label">Shopify (12)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c8" class="checkbox-custom" name="Bike" type="checkbox">
                                                          <label for="c8" class="checkbox-custom-label">PHP Script (08)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c9" class="checkbox-custom" name="Phone" type="checkbox">
                                                          <label for="c9" class="checkbox-custom-label">Drupal (32)</label>
                                                      </li>
                                                      <li>
                                                          <input id="c11" class="checkbox-custom" name="Private" type="checkbox">
                                                          <label for="c11" class="checkbox-custom-label">Joomla (50)</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Experience Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#experience" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed">Experience</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse" id="experience" data-parent="#experience">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="d1" class="checkbox-custom" name="ADA" type="checkbox" checked="">
                                                          <label for="d1" class="checkbox-custom-label">Beginner (54)</label>
                                                      </li>
                                                      <li>
                                                          <input id="d2" class="checkbox-custom" name="Parking" type="checkbox">
                                                          <label for="d2" class="checkbox-custom-label">1+ Year (32)</label>
                                                      </li>
                                                      <li>
                                                          <input id="d3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="d3" class="checkbox-custom-label">2 Year (09)</label>
                                                      </li>
                                                      <li>
                                                          <input id="d4" class="checkbox-custom" name="Mother" type="checkbox">
                                                          <label for="d4" class="checkbox-custom-label">3+ Year (16)</label>
                                                      </li>
                                                      <li>
                                                          <input id="d5" class="checkbox-custom" name="Outdoor" type="checkbox">
                                                          <label for="d5" class="checkbox-custom-label">4+ Year (17)</label>
                                                      </li>
                                                      <li>
                                                          <input id="d6" class="checkbox-custom" name="Pet" type="checkbox">
                                                          <label for="d6" class="checkbox-custom-label">5+ Year (22)</label>
                                                      </li>
                                                      <li>
                                                          <input id="d7" class="checkbox-custom" name="Beauty" type="checkbox">
                                                          <label for="d7" class="checkbox-custom-label">10+ Year (32)</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Job types Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#jbtypes" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed">Job Type</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse" id="jbtypes" data-parent="#jbtypes">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="e2" class="radio-custom" name="jtype" type="radio">
                                                          <label for="e2" class="radio-custom-label">Full time</label>
                                                      </li>
                                                      <li>
                                                          <input id="e3" class="radio-custom" name="jtype" type="radio">
                                                          <label for="e3" class="radio-custom-label">Part Time</label>
                                                      </li>
                                                      <li>
                                                          <input id="e4" class="radio-custom" name="jtype" type="radio" checked="">
                                                          <label for="e4" class="radio-custom-label">Contract Base</label>
                                                      </li>
                                                      <li>
                                                          <input id="e5" class="radio-custom" name="jtype" type="radio">
                                                          <label for="e5" class="radio-custom-label">Internship</label>
                                                      </li>
                                                      <li>
                                                          <input id="e6" class="radio-custom" name="jtype" type="radio">
                                                          <label for="e6" class="radio-custom-label">Regular</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Job Level Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#jblevel" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed">Job Level</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse" id="jblevel" data-parent="#jblevel">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="f1" class="checkbox-custom" name="ADA" type="checkbox" checked="">
                                                          <label for="f1" class="checkbox-custom-label">Team Leader</label>
                                                      </li>
                                                      <li>
                                                          <input id="f2" class="checkbox-custom" name="Parking" type="checkbox">
                                                          <label for="f2" class="checkbox-custom-label">Manager</label>
                                                      </li>
                                                      <li>
                                                          <input id="f3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="f3" class="checkbox-custom-label">Junior</label>
                                                      </li>
                                                      <li>
                                                          <input id="f4" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="f4" class="checkbox-custom-label">Senior</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Expected Salary Search -->
                          <div class="single_search_boxed">
                              <div class="widget-boxed-header">
                                  <h4>
                                      <a href="#salary" data-toggle="collapse" aria-expanded="false" role="button" class="collapsed">Expected Salary</a>
                                  </h4>

                              </div>
                              <div class="widget-boxed-body collapse" id="salary" data-parent="#salary">
                                  <div class="side-list no-border">
                                      <!-- Single Filter Card -->
                                      <div class="single_filter_card">
                                          <div class="card-body pt-0">
                                              <div class="inner_widget_link">
                                                  <ul class="no-ul-list filter-list">
                                                      <li>
                                                          <input id="g1" class="checkbox-custom" name="ADA" type="checkbox" checked="">
                                                          <label for="g1" class="checkbox-custom-label">$120k - $140k PA</label>
                                                      </li>
                                                      <li>
                                                          <input id="g2" class="checkbox-custom" name="Parking" type="checkbox">
                                                          <label for="g2" class="checkbox-custom-label">$140k - $150k PA</label>
                                                      </li>
                                                      <li>
                                                          <input id="g3" class="checkbox-custom" name="Coffee" type="checkbox">
                                                          <label for="g3" class="checkbox-custom-label">$150k - $170k PA</label>
                                                      </li>
                                                      <li>
                                                          <input id="g4" class="checkbox-custom" name="Mother" type="checkbox">
                                                          <label for="g4" class="checkbox-custom-label">$170k - $190k PA</label>
                                                      </li>
                                                      <li>
                                                          <input id="g5" class="checkbox-custom" name="Outdoor" type="checkbox">
                                                          <label for="g5" class="checkbox-custom-label">$200k - $250k PA</label>
                                                      </li>
                                                      <li>
                                                          <input id="g6" class="checkbox-custom" name="Pet" type="checkbox">
                                                          <label for="g6" class="checkbox-custom-label">$250k - $300k PA</label>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>

                      <div class="form-group filter_button pt-2">
                          <button type="submit" class="btn btn btn-theme-2 rounded full-width">22 Results Show</button>
                      </div>
                  </div>

              </div>
          </div>
      </div>


    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import NavHeader from '@/components/nav/NavHeader.vue';
import NavFooter from '@/components/nav/NavFooter.vue';

export default{
    components:{
        NavHeader,
        NavFooter
    },
    setup(){
        const store = useStore();

        // get the axios object
        // const axios = inject('axios');  // inject axios

        const navMenuStatus = computed(function(){
            return store.getters['globals/getNavMenu'];
        });

        const footerMenuStatus = computed(function(){
            return store.getters['globals/getFooterMenu'];
        });

        // when the vue module is created, get the token value, using the try catch

        // store.dispatch("auth/executeSetLoadAuthState", true);



        return {
            navMenuStatus,
            footerMenuStatus
        }

    }
    // end setup
}
</script>

<style>

.hideNavMenu{
    visibility:hidden
}

.hideFooterMenu{
    visibility:hidden
}

.tiny-font{
   font-size:10px;
}

.small-font{
   font-size:12px;
}

.normal-font{
   font-size:15px;
}

.show-dropdown{
    right: 0px;
    display: block;
}

.hide-dropdown{
    right: 0px;
    display: none;
}

.bg-dirty-white{
    background:#f5f5f5;
}

.bg-bage{
    background: #e8ebf1;
}

.cursor-pointer{
    cursor: pointer;
}

.auth-extra-link{
    color:#fff;
}

.auth-extra-link:hover{
    color:#333;
}


.twitter-bg{
    background:	#1DA1F2;
}

.twitter-color{
    color:	#1DA1F2;
}

.instagram-bg{
    background:	#e95950;
}

.instagram-color{
    color:	#e95950;
}

.facebook-bg{
    background: #1778F2;
}

.facebook-color{
    color: #1778F2;
}

.linkedin-bg{
    background: #0077b5;
}

.linkedin-color{
    color: #0077b5;
}

.pinterest-bg{
    background: #E60023;
}

.pinterest-color{
    color: #E60023;
}

</style>
