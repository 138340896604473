<template>
    <span  :class="badgeFinalStyle">
        <slot></slot>
    </span>
</template>

<script>
export default{
    props:{
        badgeColor:{
            type: String,
            required: true
        },
        roundEdge:{
            type:Boolean,
            required:false
        },
        badgePadding:{
            type:Boolean,
            required:false,
            default:true
        }
    },
    computed: {
        badgeFinalStyle(){
            const edge = this.roundEdge?'rounded-pill':'';
            const padding = this.badgePadding?'p-1':'';
            return `${edge} ${padding} my-1 bg-${this.badgeColor}`;
        }
    }
}
</script>
