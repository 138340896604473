<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span class="bi bi-chevron-left"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item active"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">...</a></li>
                <li class="page-item"><a class="page-link" href="#">18</a></li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span class="bi bi-chevron-right"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
            </ul>
        </div>
    </div>
</template>
