import { createApp } from "vue";

// import core pluggins
import router from './router';
import store from './store';
import axios from 'axios';
import urls from './site_config';

// import main app file
import App from './App.vue';

// import installed pluggins
import "bootstrap-icons/font/bootstrap-icons.css";

// import custom components
import BaseBadge from '@/components/ui/BaseBadge.vue';
import BaseImage from '@/components/ui/BaseImage.vue';
import BasePagination from '@/components/ui/BasePagination.vue';
import BaseCaptcha from '@/components/ui/BaseCaptcha.vue';
import BaseListTag from '@/components/ui/BaseListTag.vue';
import BaseSpinner from '@/components/ui/BaseSpinner.vue';
import BaseCardResponse from '@/components/ui/BaseCardResponse.vue';
import BaseModal from '@/components/ui/BaseModal.vue';

const app = createApp(App);

app.component('base-badge',BaseBadge);
app.component('base-image',BaseImage);
app.component('base-pagination',BasePagination);
app.component('base-captcha',BaseCaptcha);
app.component('base-list-tag',BaseListTag);
app.component('base-spinner',BaseSpinner);
app.component('base-card-response',BaseCardResponse);
app.component('base-modal',BaseModal);

app.provide('axios', axios)  // provide 'axios'

app.provide('coreUrl', urls.coreUrl)  // provide 'axios'

app.use(store);
app.use(router);

app.mount("#app");
