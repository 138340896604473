<template>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-transparent header-light dark-text" >
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <nav id="navigation" class="navigation navigation-landscape">
                        <div class="nav-header">
                            <router-link class="nav-brand" to="/home">
                                <base-image
                                    imgName="default_logo.png"
                                    imgGeo="logo"
                                    imgClass="logo"
                                    imgAlt="logo"
                                 >
                                 </base-image>
                            </router-link>
                            <div class="nav-toggle"></div>
                        </div>
                        <div class="nav-menus-wrapper">
                            <ul class="nav-menu">
                                <li class="col-sm-12 col-lg-4" style="padding-left:0px;">
                                    <div class="col-lg-12 col-md-12" style="padding: 20px 15px;">
                                        <form class="search-big-form">
                                                <div class="row m-0">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 p-0">
                                                        <div class="form-group">
                                                            <form action="jobs" method="GET">
                                                                <i class="bi bi-search"></i>
                                                                <input type="text" class="form-control" :value="searchValue.q"
                                                                 style="height:36px;border-radius:4px;" name="q"
                                                                 placeholder="Enter job title, keyword">
                                                             </form>
                                                         </div>
                                                    </div>


                                                </div>
                                        </form>
                                    </div>
                                </li>

                            </ul>

                            <!-- start auth nav -->
                            <ul class="nav-menu nav-menu-social align-to-right" >

                                <li>
                                    <router-link to="/jobs">
                                        <i class="bi bi-megaphone"></i>
                                        Job opportunities
                                    </router-link>
                                </li>

                                <li>
                                    <router-link to="/knowledge">
                                        <i class="bi bi-book-fill"></i>
                                        Knowledge base
                                    </router-link>
                                </li>
                                <li v-if="!isAuthenticated">
                                    <a href="#">
                                        <i class="bi bi-power"></i>
                                        Get started
                                    </a>
                                    <ul class="nav-dropdown ">
                                        <li>
                                            <router-link to="/login">
                                                <i class="bi bi-binoculars-fill"></i>
                                                as job seeker
                                            </router-link>
                                        </li>
                                        <li>
                                            <a :href="coreUrl.tmsUrl">
                                                <i class="bi bi-megaphone-fill"></i>
                                                to post jobs
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li v-if="isAuthenticated" @click="toggleAccountDropDown">
                                    <!-- data-toggle="modal" data-target="#login" -->
                                    <a href="javascript:void(0);"  >

                                        <i class="bi bi-person mr-1"></i>
                                        My account
                                    </a>
                                    <ul class="nav-dropdown nav-submenu mr-2" :class="accountDropDown?'show-dropdown':'hide-dropdown'" >
                                        <li>
                                            <router-link to="/dashboard">
                                                <i class="bi bi-person"></i> Dashboard
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/profile">
                                                <i class="bi bi-file-richtext"></i> My Resume
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/jobs">
                                                <i class="bi bi-bell"></i> Notification
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/jobs">
                                                <i class="bi bi-envelope-open-fill"></i> Messages
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/account_settings/password">
                                                <i class="bi bi-gear-fill"></i> Account Settings
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/logout">
                                                <i class="bi bi-power"></i> Logout
                                            </router-link>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                            <!-- end auth nav -->

                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
</template>

<script>
import { ref,computed,inject } from 'vue';
import { useStore } from 'vuex';
// @ is an alias to /src

export default {
  name: 'AuthSignUp',
  setup(){
      const coreUrl = inject('coreUrl');
      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      const isAuthenticated = computed(function(){
          return store.getters['auth/getAuthAuthentication'].isAuthenticated;
      });


      const accountDropDown = ref(false);

      function toggleAccountDropDown(){
          accountDropDown.value = !accountDropDown.value;
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const searchValue = Object.fromEntries(urlSearchParams.entries());


      return {
          coreUrl,
          isAuthenticated,
          accountDropDown,
          toggleAccountDropDown,
          searchValue
      }


  }

};
</script>
