<template>
    <div>
        <div class="card " :class="cardFinalStyle">
            <div class="card-body">
                    <h3 class="card-title" :class="headerFinalStyle">
                       <slot name="header"></slot>
                   </h3>
                    <p class="card-text">
                       <slot> </slot>
                    </p>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: {
        cardType:{
            type: String,
            required: false,
            default: 'success'
        },
        cardColor:{
            type: String,
            required: false,
            default: 'text-white'
        }
    },
    computed:{
        cardFinalStyle(){
            return `bg-${this.cardType} ${this.cardColor}`;
        },
        headerFinalStyle(){
            return `${this.cardColor}`;
        }
    }
}
</script>

<style scoped>
.bg-beige{
    background-color:#f5f5f5;
}
</style>
