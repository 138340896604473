import mutations from './mutations';
import actions from './actions';
import getters from './getters';



export default {
    namespaced:true,
    state(){
        return {
            accountData: {
                bio:null,
                work_experience:null,
                qualifications:null,
                skills:null,
                accomplishments:null
            }
        }
    },
    mutations:mutations,
    actions:actions,
    getters:getters
};
