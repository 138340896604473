<!-- <template>
    <img
        :src="imgFinalSource"
        :class="imgClass"
        :height="imgFinalHeight"
        :width="imgFinalWidth"
        :alt="imgAlt"
    />
</template>

<script>
export default{
    props:{
        imgName:{
            type: String,
            required: true
        },
        imgGeo:{
            type:String,
            required:true
        },
        imgAlt:{
            type:String,
            required:false
        },
        imgDim:{
            type:Array,
            required:false
        },
        imgType:{
            type: String,
            required: false,
            default:'static'
        },
        imgClass:{
            type: String,
            required: false
        }
    },
    computed:{
        imgFinalSource(){

            /* Image origin, joining the image origin , which is either static, for files saved locally
               or dynamic for files saved on the cloud, if the dynamic load fails, image is loaded locally
            */
            return require('@/assets/images/'+this.imgGeo+'/'+this.imgName);
        },
        imgFinalHeight(){
            return this.imgDim?this.imgDim[0]:null;
        },
        imgFinalWidth(){
            return this.imgDim?this.imgDim[1]:null;
        }
    }
}
</script> -->


<template>
    <img
        :src="imgFinalSource"
        :class="imgClass"
        :height="imgFinalHeight"
        :width="imgFinalWidth"
        :alt="imgAlt"
    />
</template>

<script>

import { computed,inject } from 'vue'

export default{
    props:{
        imgName:{
            type: String,
            required: true
        },
        imgHost:{
            type:String,
            default:"local"
        },
        imgGeo:{
            type:String,
            required:true
        },
        imgAlt:{
            type:String,
            required:false
        },
        imgDim:{
            type:Array,
            required:false,
            default:null
        },
        imgType:{
            type: String,
            required: false,
            default:'static'
        },
        imgClass:{
            type: String,
            required: false
        }
    },
    setup( props ){

        //get the urls
        const coreUrl = inject('coreUrl');

        const imgFinalSource = computed(function(){

            /* Image origin, joining the image origin , which is either static, for files saved locally
               or dynamic for files saved on the cloud, if the dynamic load fails, image is loaded locally
            */
            // let destination = '@/assets/images/'+props.imgGeo+'/'+props.imgName;
            const date = new Date();

            const dateTimeInSeconds = Math.floor(date.getTime() / 1000);

            if(props.imgHost === 'remote'){
                return `${coreUrl.backendStaticUrl}get_profile_picture?${dateTimeInSeconds}&geo=${props.imgGeo}&name=${props.imgName}`;
            }

            return require('@/assets/images/'+props.imgGeo+'/'+props.imgName);
        })

        const altImgFinalSource = computed(function(){

            return require('@/assets/images/'+props.imgGeo+'/default.png');
        })

        const imgFinalHeight = computed(function(){

            return props.imgDim?props.imgDim[0]:null;
        })

        const imgFinalWidth = computed(function(){

            return props.imgDim?props.imgDim[1]:null;
        })

        return {
            imgFinalSource,
            imgFinalHeight,
            imgFinalWidth,
            altImgFinalSource
        }

    }
}
</script>
