import { createRouter, createWebHistory } from "vue-router";

import store from '@/store/index';
import axios from 'axios';
import urls from '@/site_config/index';

const coreUrl = urls.coreUrl;


const routes = [
  { path: "/", redirect:"/home"},
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/for_organizations",
    name: "ForOrganizations",
    component: () => import("../views/ForOrganizations.vue")
  },
  {
    path: "/login",
    name: "AuthLogin",
    component: () => import("../views/auth/AuthLogin.vue"),
    meta: { requiresUnauth: true}
  },
  {
    path: "/sign_up",
    name: "AuthSignUp",
    component: () => import("../views/auth/AuthSignUp.vue"),
    meta: { requiresUnauth: true}
  },
  {
    path: "/forgot_password",
    name: "AuthForgotPassword",
    component: () => import("../views/auth/AuthForgotPassword.vue"),
    meta: { requiresUnauth: true}
  },
  {
    path: "/reset_password/:resetId",
    name: "AuthResetPassword",
    props: true,
    component: () => import("../views/auth/AuthResetPassword.vue"),
    meta: { requiresUnauth: true}
  },
  {
    path: "/confirm_account/:confirmId",
    name: "AuthConfirmAccount",
    props: true,
    component: () => import("../views/auth/AuthConfirmAccount.vue")
  },
  {
    path: "/logout",
    name: "AuthLogout",
    component: () => import("../views/auth/AuthLogout.vue")
  },
  {
    path: "/jobs",
    name: "JobsList",
    component: () => import("../views/JobsList.vue")
  },
  {
    path: "/job_details/:jobLinkTitle/:jobLinkId",
    name: "JobDirectDetails",
    component: () => import("../views/JobDirectDetails.vue"),
    props: true
  },
  {
    path: "/organizations",
    name: "OrganizationsList",
    component: () => import("../views/OrganizationsList.vue")
  },
  {
    path: "/knowledge",
    name: "KnowledgeList",
    component: () => import("../views/KnowledgeList.vue")
  },
  {
    path: "/application_history",
    name: "ApplicationHistory",
    component: () => import("../views/ApplicationHistory.vue"),
    meta: { requiresAuth: true}
  },
  {
    path: "/bookmark_history",
    name: "BookmarkHistory",
    component: () => import("../views/BookmarkHistory.vue"),
    meta: { requiresAuth: true}
  },
  {
    path: "/dashboard",
    name: "DashboardReport",
    component: () => import("../views/DashboardReport.vue"),
    meta: { requiresAuth: true}
  },
  {
    path: "/profile",
    name: "ProfileDetails",
    component: () => import("../views/ProfileDetails.vue"),
    meta: { requiresAuth: true}
  },
  {
    path: "/account_settings/:settingsType",
    name: "AccountSettings",
    component: () => import("../views/AccountSettings.vue"),
    meta: { requiresAuth: true},
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeResolve(async (to, _, next) => {
    if(store.getters['auth/getLoadAuthState']){
        let canAccess = store.getters['auth/getAuthAuthentication'].isAuthenticated
        if (to.meta.requiresAuth && !canAccess ){
            next('/login');
        } else if (to.meta.requiresUnauth && canAccess ) {
            // window.location.replace(coreUrl.jobsUrl+'dashboard');
            next('/dashboard');
        } else {
            next();
        }
    }else{
        let canAccess = false;
        try{
            canAccess = await getGlobalAuthState();
        }catch(e){
            canAccess = e;
        }

        console.log(canAccess)


         if (to.meta.requiresAuth && !canAccess ){
             next('/login');
         } else if (to.meta.requiresUnauth && canAccess ) {
             // window.location.replace(coreUrl.jobsUrl+'dashboard');
             next('/dashboard');
         } else {
             next();
         }
    }





})




// get the current auth live state
function getGlobalAuthState(){

    return new Promise((resolve,reject)=>{

        let responseData = false;

        axios.get(coreUrl.backendUrl+'get_ind_session_data',{withCredentials: true}).then((response) =>{
            // return the full token to the token variable
            // If successful, get the resppnse data
            responseData = response.data.data;

            if(responseData){

                let payload = {
                    data:responseData.session_data,
                    state:true
                }

                store.dispatch("auth/executeSetLoadAuthState", payload);
                store.dispatch("account/executeSetAccountData", responseData.profile_data);

                resolve(response.data.status)

            }else{

                reject(false)
            }



        }).catch((error)=>{

               // if an error occours, do this
            reject(error)
        })



    });

}



export default router
