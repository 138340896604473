<template>
    <!-- =========================== Footer Start ========================================= -->
    <footer class=" mb-5 p-5 ">
        <div class="d-none d-lg-block">
            <div class="container">
                <div class="row">

                    <div class="col-lg-4 col-md-6">
                        <div class="footer-widget">
                            <base-image
                                imgName="default_logo.png"
                                imgGeo="logo"
                                imgClass="logo"
                                imgAlt="logo"
                             >
                             </base-image>
                            <p>407-472 Somewher in Accra, Ghana<br>Quebec, H2Y 2V8</p>
                            <ul class="footer-bottom-social">
                                <li><a href="#"><i class="bi bi-facebook"></i></a></li>
                                <li><a href="#"><i class="bi bi-twitter"></i></a></li>
                                <li><a href="#"><i class="bi bi-instagram"></i></a></li>
                                <li><a href="#"><i class="bi bi-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget">
                            <h4 class="widget-title">Useful links</h4>
                            <ul class="footer-menu">
                                <li><a href="#">About Us</a></li>
                                <li><a href="#">Getting started</a></li>
                                <li><a href="#">Blogs</a></li>
                                <li><a href="#">Release note</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget">
                            <h4 class="widget-title">Ecosystem</h4>
                            <ul class="footer-menu">
                                <li><a href="#">Sabenzi Jobs</a></li>
                                <li><a href="#">Sabenzi TMS</a></li>
                                <li><a href="#">Sabenzi Tutor</a></li>
                                <li><a href="#">Sabenzi TDS</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget">
                            <h4 class="widget-title">Media</h4>
                            <ul class="footer-menu">
                                <li><a href="#">Terms of use</a></li>
                                <li><a href="#">Guidelines</a></li>
                                <li><a href="#">Privacy & Cookies</a></li>
                                <li><a href="#">Press</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget">
                            <h4 class="widget-title">Support</h4>
                            <ul class="footer-menu">
                                <li><a href="#">Advertisers</a></li>
                                <li><a href="#">Careers</a></li>
                                <li><a href="#">FAQs</a></li>
                                <li><a href="#">Contact us</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="container">
                <div class="row align-items-center">

                    <div class="col-lg-12 col-md-12 text-center">
                        <p class="mb-0">
                            "Sabenzi" and logo are registered trademarks of Sabenzi.
                        </p>
                        <p class="mb-2">
                            Copyright &copy; 2017-2021, Sabenzi, Inc.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </footer>
    <!-- =========================== Footer End ========================================= -->
</template>
